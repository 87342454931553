<template>
    <div id="Drivers" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Listado de Conductores
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="drivers_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Conductor
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && drivers_data.length > 0">
                <li-table :data="drivers_data" 
                    :allow_headers="driver_headers"
                    pagination_off>
                    <template v-slot:picture="data">
                        <img :src="data.item.picture" width="100" height="100" style="object-fit: cover;">
                    </template>
                    <template v-slot:details="data">
                        <button @click.stop="$router.push({ name: 'driver-profile', params: { id: data.item.id_driver } })"
                        class="btn btn-outline-primary btn-pill p-0 inter-bold"
                        style="width: 90px;"
                            type="button">
                            Detalles
                        </button>
                    </template>
                    <template v-slot:edit="data">
                        <button @click.stop="selectDriver('edit', data.item)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Editar
                        </button>
                    </template>
                    <template v-slot:delete="data">
                        <button @click.stop="selectDriver('delete', data.item)" v-if="data.item.active"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Eliminar
                        </button>
                        <button @click.stop="selectDriver('activate', data.item)" v-if="!data.item.active"
                            class="btn btn-outline-primary btn-pill p-0 interbold"
                            style="width: 90px;"
                            type="button">
                            Activar
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && drivers_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                        
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        :disabled="page > 1"
                        class="form-control bg-secondary text-white border-0"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                            <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                            <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="drivers_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3 v-if="selected_id == null">
                       Añadir conductor
                    </h3>

                    <h3 v-if="selected_id">
                       Editar Conductor
                    </h3>
                </div>

                <div class="col-12 col-md-3 py-3 py-md-0" v-if="selected_id">
                    <button @click.stop="set_documentation = !set_documentation"
                        class="btn btn-primary btn-pill mr-3" 
                        style="height: 35px; padding: 0 20px;"
                        type="button">
                        Documentación
                    </button>
                </div>

                <div class="col-12  d-flex justify-content-md-end"
                    :class="[`${selected_id ? 'col-md-3' : 'col-md-6'}`]">

                    <button @click.stop="createEditDriver" 
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="() => {drivers_modal = false; clearDriverData()}"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

                <div class="col-12 m-0 p-0" v-if="!set_documentation">
                    <div class="row m-0 p-0">

                        <div class="col-3">
                            <div class="row">
                                <div class="col-12">
                                    <p>
                                        Foto de perfil
                                    </p>
                                    <li-dropfile _id="perfil_picture" v-if="!picture"
                                        @charged="checkFile"/>

                                    <div class="d-flex flex-column  py-2" v-if="picture">
                                        <img class="img-fluid"
                                            :src="picture" />
                                            <button @click.stop="picture = null"
                                                class="btn btn-primary mt-2"
                                                type="button">
                                                Cambiar
                                            </button>
                                    </div>
                                    <span class="text-danger" v-if="errors.picture">
                                        {{ errors.picture}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-9">
                            <div class="row">

                                <div class="col-12 col-md-4 p-2 opensans-bold">
                                    <li-input label="Nombre(s)"
                                        placeholder="Nombre(s)"
                                        v-model="first_name"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.first_name">
                                        {{ errors.first_name }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-4 p-2 opensans-bold">
                                    <li-input label="Apellido Paterno"
                                        placeholder="Apellido Paterno"
                                        v-model="last_name"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.last_name">
                                        {{ errors.last_name }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-4 p-2 opensans-bold">
                                    <li-input label="Apellido Materno"
                                        placeholder="Apellido Materno"
                                        v-model="second_surname"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.second_surname">
                                        {{ errors.second_surname }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-4 p-2 opensans-bold">
                                    <li-select label="Género"
                                        variant="secondary"
                                        :options="[{id:'0', label: 'Seleccione'},{id: 'female', label: 'Femenino'}, { id: 'male', label: 'Masculino'}]"
                                        v-model="gender"
                                        full>
                                    </li-select>
                                    <span class="text-danger" v-if="errors.gender">
                                        {{ errors.gender }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-8 p-2 opensans-bold">
                                    <li-input label="Correo"
                                        placeholder="Correo"
                                        v-model="email"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.email">
                                        {{ errors.email }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-6 p-2 opensans-bold">
                                    <li-input label="Télefono"
                                        placeholder="Télefono"
                                        v-model="phone"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.phone">
                                        {{ errors.phone }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-6 p-2 opensans-bold">
                                    <li-input label="Fianza:"
                                        placeholder="Fianza"
                                        v-model="incident_fund"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.incident_fund">
                                        {{ errors.incident_fund }}
                                    </span>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12 m-0 p-0" v-if="set_documentation">
                    <div class="row m-0 p-0">
                        <div class="col-3">
                            <div class="row">
                                <div class="col-12">
                                    <p>
                                        Foto de domicilio
                                    </p>
                                    <li-dropfile _id="address_picture" v-if="!address_picture"
                                        @charged="checkFile"/>

                                    <div class="d-flex flex-column  py-2" v-if="address_picture">
                                        <img class="img-fluid"
                                            :src="address_picture" />
                                            <button @click.stop="address_picture = null"
                                                class="btn btn-primary mt-2"
                                                type="button">
                                                Cambiar
                                            </button>
                                    </div>
                                    <span class="text-danger" v-if="errors.address_picture">
                                        {{ errors.adress_picture}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-9">
                            <div class="row">
                                <div class="col-12 col-md-4 p-2 opensans-bold">
                                    <li-select label="Región"
                                        variant="secondary"
                                        :options="region_options"
                                        v-model="id_region"
                                        full>
                                    </li-select>
                                    <span class="text-danger" v-if="errors.id_region">
                                        {{ errors.id_region }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-4 p-2 opensans-bold">
                                    <li-input label="Municipio"
                                        placeholder="Municipio"
                                        v-model="municipality"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.municipality">
                                        {{ errors.municipality }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-4 p-2 opensans-bold">
                                    <li-input label="Ciudad/Localidad"
                                        placeholder="Ciudad"
                                        v-model="city"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.city">
                                        {{ errors.city }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-3 p-2 opensans-bold">
                                    <li-input label="Colonia"
                                        placeholder="Colonia"
                                        v-model="colony"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.colony">
                                        {{ errors.colony }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-9 p-2 opensans-bold">
                                    <li-input label="Calle"
                                        placeholder="Calle"
                                        v-model="street"
                                        type="text"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.street">
                                        {{ errors.street }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-3 p-2 opensans-bold">
                                    <li-input label="Número exterior:"
                                        placeholder="Número exterior"
                                        v-model="outside_number"
                                        type="number"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.outside_number">
                                        {{ errors.outside_number }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-3 p-2 opensans-bold">
                                    <li-input label="Número interior:"
                                        placeholder="Número interior"
                                        v-model="inside_number"
                                        type="number"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.inside_number">
                                        {{ errors.inside_number }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-3 p-2 opensans-bold">
                                    <li-input label="Código postal:"
                                        placeholder="Código postal"
                                        v-model="cp_code"
                                        type="number"
                                        full>
                                    </li-input>
                                    <span class="text-danger" v-if="errors.cp_code">
                                        {{ errors.cp_code }}
                                    </span>
                                </div>

                                <div class="col-12 col-md-3 p-2 pt-4 opensans-bold d-flex align-items-center justify-content-center">
                                    <button @click.stop="createNewAddress"
                                        class="btn btn-outline-primary btn-pill px-2 py-0 mr-3"
                                        type="button">
                                        Nuevo
                                    </button>

                                    <button @click.stop="updateAddress"
                                        class="btn btn-outline-primary btn-pill px-2 py-0 mr-3"
                                        type="button">
                                        Guadar
                                    </button>
                                </div>
                                
                            </div>
                        </div>

                        <div class="col-12 d-flex align-items-center">
                            <span class="px-3 opensans-bold text-secondary">
                                Documentos
                            </span>
                            <hr class="border border-secondary w-100"> 
                        </div>

                        <div class="col-12">
                            <div class="row justify-content-between" v-for="(document, index) in document_options" :key="`document-${index}`">
                                <div class="col-12 text-center">
                                    {{ document.name}}
                                </div>
                                <div class="col-3">
                                    <p>
                                        Vista frontal
                                    </p>
                                    <li-dropfile :_id="`frontal-${document.name.replaceAll(' ','-')}`" v-if="!document.frontal_view"
                                        @charged="(value) => convertDocument(value, 'frontal', document.name)"/>

                                    <div class="d-flex flex-column  py-2" v-if="document.frontal_view">
                                        <img class="img-fluid"
                                            :src="document.frontal_view" />
                                            <button @click.stop="resetDocument('frontal', document.name)"
                                                class="btn btn-primary mt-2"
                                                type="button">
                                                Cambiar
                                            </button>
                                    </div>
                                </div>
                                <div class="col-3" v-if="document.hasOwnProperty('reverse_view')">
                                    <p>
                                        Reverso
                                    </p>
                                    <li-dropfile :_id="`reverse-${document.name}`" v-if="!document.reverse_view"
                                        @charged="(value) => convertDocument(value, 'reverse', document.name)"/>

                                    <div class="d-flex flex-column  py-2" v-if="document.reverse_view">
                                        <img class="img-fluid"
                                            :src="document.reverse_view" />
                                            <button @click.stop="resetDocument('reverse', document.name)"
                                                class="btn btn-primary mt-2"
                                                type="button">
                                                Cambiar
                                            </button>
                                    </div>
                                </div>

                                <div class="col">
                                    <div class="row">
                                        <div class="col-12 p-2 opensans-bold" v-if="document.hasOwnProperty('expiration_date')">
                                            <li-input label="Fecha de expiracion" 
                                                v-model="document.expiration_date"
                                                type="date"
                                                full>
                                            </li-input>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button @click.stop="sendDocument(document.id_driver_document_type, document.name)"
                                            class="btn btn-outline-primary px-3 py-2 btn-pill"
                                            type="button">
                                            Guardar
                                        </button>
                                    </div>
                                    <div class="col-12" v-if="document.error">
                                        <span class="text-danger opensans-bold">
                                            {{ document.error }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <hr class="border-secondary w-100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h3>
                        ¿Esta seguro de eliminar este conductor? 
                        <br>
                        Esta acción no puede revertirse
                    </h3>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="deleteDriver"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="delete_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="activate_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de activar este conductor? 
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="activateDriver"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="activate_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

    </div>
</template>

<script>
    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                drivers_modal: false,
                delete_modal: false,
                activate_modal: false,
                selected_id: null,
                set_documentation: false,
                driver_headers: [
                    {key: 'picture', label: 'Foto'},
                    {key: 'first_name', label: 'Nombres'},
                    {key: 'last_name', label: 'Apellido paterno'},
                    {key: 'second_surname', label: 'Apellido materno'},
                    {key: 'phone', label: 'Telefono'},
                    {key: 'gender', label: 'Genero'},
                    {key: 'email', label: 'Correo'},
                    {key: 'details'},
                    {key: 'edit'},
                    {key: 'delete'}
                ],
                drivers_data: [],
                region_options: [],
                first_name: null,
                last_name: null,
                second_surname: null,
                phone: null,
                email: null,
                gender: '0',
                city: null,
                street: null,
                cp_code: null,
                picture: null,
                address_picture: null,
                outside_number: null,
                inside_number: null,
                incident_fund: null,
                municipality: null,
                colony: null,
                id_region: '0',
                id_driver_address: null,
                movement: 'new',
                errors: {
                    first_name: null,
                    last_name: null,
                    second_surname: null,
                    phone: null,
                    email: null,
                    gender: null,
                    city: null,
                    street: null,
                    cp_code: null,
                    picture: null,
                    incident_fund: null,
                    address_picture: null,
                    id_region: null,
                    colony: null,
                    municipality: null,
                    outside_number: null,
                    inside_number: null,
                },
                filter: 'active',
                //drop
                document_options: null,
                files: [],
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2'
            }
        },
        computed: {
            ...mapGetters({
                drivers: 'drivers/getDrivers',
                regions: 'regions/getRegions',
                driver: 'drivers/getDriver',
                last_page: 'drivers/getLastPage',
                total_pages: 'drivers/getTotalPages',
                document_types: 'document_types/getDocumentTypes'
            })
        },
        methods: {
            //helpers
            clearDriverData(){
                this.first_name = null
                this.last_name = null
                this.second_surname = null
                this.phone = null
                this.email = null
                this.gender = null
                this.city = null
                this.street = null
                this.cp_code = null
                this.set_documentation = false
                this.picture = null

                this.street = null,
                this.postal_code = null,
                this.id_driver = null,
                this.street = null,
                this.colony = null,
                this.id_region = '0',
                this.outside_number = null,
                this.inside_number = null,
                this.municipality = null,
                this.movement = 'new'

                for(let error in this.errors) {
                    this.errors[error] = null
                }

                this.document_options = this.document_types.map((document) => {

                    let config = {
                        frontal_view: null,
                        error: null
                    }

                    if(document.reverse_image) {
                        config.reverse_view = null
                    }
                    
                    if(document.has_expiration) {
                        config.expiration_date = null
                    }   

                    return Object.assign({}, document, {...config});
                })

                this.$forceUpdate();
            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('drivers/listDrivers', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('drivers/listDrivers', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('drivers/listDrivers', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.drivers === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.drivers_data = this.drivers.map( driver => {
                    return {
                        ...driver,
                        gender: driver.gender == 'male' ? 'Masculino' : driver.gender == 'female' ? 'Femenino' : '',
                        details: '-',
                        edit: '-',
                        delete: '-'
                    }
                })
            },
            checkFile(data) {
                console.log(data)
                if(data.id == 'perfil_picture') {
                    this.picture = data.url;
                }

                else if(data.id == 'address_picture') {
                    this.address_picture = data.url;
                }
                
            },
            convertDocument(data, type, document) {
                let index = this.document_options.findIndex((item) => item.name == document);
                switch(type) {
                    case 'frontal':      
                        this.document_options[index].frontal_view = data.url
                        break;
                    case 'reverse':
                        this.document_options[index].reverse_view = data.url
                        break;
                    default:
                        console.log('not case founded')
                }
                this.$forceUpdate();
            },
            resetDocument(type, document) {
                let index = this.document_options.findIndex((item) => item.name == document);
                switch(type) {
                    case 'frontal':
                        this.document_options[index].frontal_view = null;
                        break;
                    case 'reverse':
                        this.document_options[index].reverse_view = null;
                        break;
                    default:
                        console.log('option invalid')
                }

                this.$forceUpdate()
            },
            createNewAddress() {
                this.movement = 'new';
                this.id_region = '0'
                this.colony = null;
                this.city = null;
                this.street = null;
                this.outside_number = null;
                this.inside_number = null;
                this.cp_code = null;
                this.municipality = null;
                this.address_picture = null;
                this.id_driver_address = null;
            },
            //drivers CRUD
            async selectDriver(type, data) {
                switch(type) {
                    case 'edit':

                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }

                        this.selected_id = data.id_driver
                        await this.$store.dispatch('drivers/viewDriver', { id_driver: this.selected_id, only_last: true})
                        this.first_name = this.driver.first_name
                        this.last_name = this.driver.last_name
                        this.second_surname = this.driver.second_surname
                        this.phone = this.driver.phone
                        this.email = this.driver.email
                        this.gender = this.driver.gender
                        this.incident_fund = this.driver.incident_fund
                        this.picture = this.driver.picture

                        this.driver.driver_document_types.forEach(element => {
                            let index = this.document_options.findIndex((item) => item.name == element.name);


                            if(this.document_options[index].hasOwnProperty('expiration_date') && element.item.length > 0) {
                                this.document_options[index].expiration_date = new Date(element.item[0].expiration_date).toISOString().split('T')[0] 
                            }

                            if(this.document_options[index].hasOwnProperty('reverse_view') && element.item.length > 0) {
                                this.document_options[index].reverse_view = element.item[0].subsequent_image
                            }

                            if(element.item.length > 0) {
                                this.document_options[index].frontal_view = element.item[0].frontal_image
                            }
                        });

                        if(this.driver.hasOwnProperty('driver_address') && this.driver.driver_address !== null && this.driver.driver_address.length > 0) {
                            this.movement = 'change';
                            this.id_region = this.driver.driver_address[0].id_region.toString();
                            this.colony = this.driver.driver_address[0].colony;
                            this.city = this.driver.driver_address[0].city;
                            this.street = this.driver.driver_address[0].street;
                            this.outside_number = this.driver.driver_address[0].outer_number;
                            this.inside_number = this.driver.driver_address[0].inside_number;
                            this.cp_code = this.driver.driver_address[0].postal_code;
                            this.municipality = this.driver.driver_address[0].municipality;
                            this.address_picture = this.driver.driver_address[0].picture;
                            this.id_driver_address = this.driver.driver_address[0].id_driver_address;
                        }
                        else {
                            this.movement = 'new'
                        }

                        this.drivers_modal = true
                        return;
                    case 'delete':
                        this.selected_id = data.id_driver
                        this.delete_modal = true
                        return;
                    case 'activate':

                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async createEditDriver() {

                if(this.set_documentation) {
                    this.sendDocuments()
                    this.updateAddress()
                    return
                }

                let complete = true;
                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(_.isEmpty(this.first_name) || _.isNull(this.first_name)) {
                    complete = false
                    this.errors['first_name'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.last_name) || _.isNull(this.last_name)) {
                    complete = false
                    this.errors['last_name'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.second_surname) || _.isNull(this.second_surname)) {
                    complete = false
                    this.errors['second_surname'] = '*Campo obligatorio' 
                }

                if(_.isEmpty(this.phone) || _.isNull(this.phone)) {
                    complete = false
                    this.errors['phone'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.picture) || _.isNull(this.picture)) {
                    complete = false
                    this.errors['picture'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.incident_fund) || _.isNull(this.incident_fund)) {
                    complete = false
                    this.errors['incident_fund'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.gender) || _.isNull(this.gender) || this.gender == '0') {
                    complete = false
                    this.errors['gender'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.email) || _.isNull(this.email)) {
                    complete = false
                    this.errors['email'] = '*Campo obligatorio'
                }

                

                if(complete) {
                    const payload = {
                        first_name: this.first_name,
                        last_name: this.last_name,
                        second_surname: this.second_surname, 
                        phone: this.phone, 
                        email: this.email,
                        gender: this.gender,
                        picture: this.picture,
                        incident_fund: this.incident_fund 
                    }

                    if(this.selected_id) {
                        payload.id_driver = this.selected_id
                        await this.$store.dispatch('drivers/updateDriver', payload)
                        this.filterElements()
                        this.drivers_modal = false

                        this.clearDriverData()
                        
                        this.selected_id = null
                    }

                    else {
                        await this.$store.dispatch('drivers/addDriver', payload)
                        this.filterElements()

                        this.clearDriverData()

                        this.drivers_modal = false
                    }
                }
            },
            async deleteDriver() {
                await this.$store.dispatch('drivers/deleteDriver', { id_driver: this.selected_id, status: 'inactive'})
                this.filterElements()

                this.delete_modal = false
                this.selected_id = null
            },
            async activateBrand(){
                await this.$store.dispatch('drivers/deleteDriver', {id_spare_part: this.selected_id, status: 'active'})
                this.filterElements()
                this.activate_modal = false
                this.selected_id = null
            },
            async updateAddress() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.id_region) || this.id_region == '0') {
                    this.errors['id_region'] = '*Campo obligatorio'
                    complete = false
                }

                if(_.isEmpty(this.city) || _.isNull(this.city)) {
                    this.errors['city'] = '*Campo obligatorio'
                    complete = false
                }

                if(_.isEmpty(this.street) || _.isNull(this.street)) {
                    this.errors['street'] = '*Campo obligatorio'
                    complete = false
                }

                if(_.isEmpty(this.municipality) || _.isNull(this.municipality)) {
                    this.errors['municipality'] = '*Campo obligatorio'
                    complete = false
                }

                if(_.isEmpty(this.cp_code) || _.isNull(this.cp_code)) {
                    this.errors['cp_code'] = '*Campo obligatorio'
                    complete = false
                    
                }

                if(_.isEmpty(this.address_picture) || _.isNull(this.address_picture)) {
                    this.errors['address_picture'] = '*Campo obligatorio'
                    complete = false
                    
                }

                if(_.isEmpty(this.outside_number) || _.isNull(this.outside_number)) {
                    this.errors['outside_number'] = '*Campo obligatorio'
                    complete = false
                    
                }

                if(_.isEmpty(this.inside_number) || _.isNull(this.inside_number)) {
                    this.errors['inside_number'] = '*Campo obligatorio'
                    complete = false
                    return
                }

                if(_.isEmpty(this.colony) || _.isNull(this.colony)) {
                    this.errors['colony'] = '*Campo obligatorio'
                    complete = false
                    return
                }


                if(complete) {
                    const payload = {
                        city: this.city,
                        street: this.street,
                        postal_code: this.cp_code,
                        id_driver: this.selected_id,
                        street: this.street,
                        picture: this.address_picture,
                        colony: this.colony,
                        id_region: this.id_region,
                        outside_number: this.outside_number,
                        inside_number: this.inside_number,
                        municipality: this.municipality,
                        movement: this.movement
                    }

                    if(this.movement == 'change') {
                        payload.id_driver_address = this.id_driver_address;
                    }

                    await this.$store.dispatch('drivers/adressUpdate', payload)
                    this.clearDriverData()
                    this.drivers_modal = false
                }
            },
            async sendDocument(document_type, document_name){
                let index = this.document_options.findIndex((item) => item.name == document_name);

                let data = this.document_options[index];
                data.error = ''
                let complete = true

                let error_message = 'Los siguientes datos faltan: '

                if(data.has_expiration == 1 && data.expiration_date == null) {
                    error_message = error_message + ' Fecha de expiración,'
                    complete = false
                    data.error = error_message
                }

                if(data.reverse_image == 1 && data.reverse_view == null) {
                    error_message = error_message + ' vista reverso,'
                    data.error = error_message
                    complete = false
                }

                if(data.frontal_view == null) {
                    error_message = error_message + ' vista frontal.'
                    data.error = error_message
                    complete = false
                }

                if(complete) {
                    let payload = {
                        query: {
                            id_driver: this.selected_id
                        }, 
                        body:{
                                documents: [{
                                    id_driver_document_type: document_type,
                                    frontal_image: data.frontal_view
                                }]   
                            }
                        }

                    if(data.has_expiration == 1) {
                        payload.body.documents[0].expiration = data.expiration_date
                    }

                    if(data.reverse_image == 1) {
                        payload.body.documents[0].subsequent_image = data.reverse_view
                    }

                    //peticion
                    await this.$store.dispatch('drivers/sendDocument', payload)

                    //reset de datos

                    if(data.hasOwnProperty('expiration_date')) {
                        data.expiration_date = null
                    }

                    if(data.hasOwnProperty('reverse_view')) {
                        data.reverse_view = null;
                    }

                    await this.filterElements()
                }
                else {
                    this.$forceUpdate()
                }

            },
            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            try {
                //lista de condutores
                await this.$store.dispatch('drivers/listDrivers', {datatable: true, page: this.page, rows: this.rows, status: 'active', order_asc: false})
                
                this.drivers_data = this.drivers.map( driver => {
                    return {
                        picture: driver.picture,
                        ...driver,
                        gender: driver.gender == 'male' ? 'Masculino' : driver.gender == 'female' ? 'Femenino' : '',
                        details: '-',
                        edit: '-',
                        delete: '-'
                    }
                })

                //lista de regiones
                await this.$store.dispatch('regions/listRegions', { datatable: false, status: 'active'})
                this.region_options = this.regions.map((region) => {
                    return {
                        id: region.id_region,
                        label: region.name
                    }
                })
                this.region_options = [
                    {id: '0', label: 'Seleccione'},
                    ...this.region_options
                ];

                //lista de documentos:
                await this.$store.dispatch('document_types/list', { datatable:false, active: true});

                this.document_options = this.document_types.map((document) => {

                    let config = {
                        frontal_view: null,
                        error: null
                    }

                    if(document.reverse_image) {
                        config.reverse_view = null
                    }
                    
                    if(document.has_expiration) {
                        config.expiration_date = null
                    }   

                    return Object.assign({}, document, {...config});
                })

                this.loading = false
                this.fetch_error = false
            }

            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Drivers {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>